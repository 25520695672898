import { createStore } from 'redux';

// Defining the initial state
const initialState = {
  utmParams: {},
};

// Defining the reducer function
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_UTM_PARAMS":
      return {
        ...state,
        utmParams: action.payload
      };
    default:
      return state;
  }
};

// Creating the store
// const store = createStore(reducer);

// preloadedState will be passed in by the plugin
export default preloadedState => {
  return createStore(reducer, preloadedState);
};

